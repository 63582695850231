<template>
  <table class="table">
    <tbody>
    <tr class="bg-transparent">
      <td
        colspan="2"
        :class="`fs${useBigFont ? 35 : 25} time-bg`"
      >
        <span>
          {{ order.requested_time_is_asap ? 'ASAP' : dateFormat(order.requested_delivery_time) }}
        </span>
        <span
          :class="`badge badge-${order.type === 'collection' ? 'info' : 'primary'} fs15 ms-2`"
        >
          {{ order.type }}
        </span>
      </td>
    </tr>

    <tr v-if="showPrice && showPriceInfo">
      <td colspan="2" class="text-danger">
        <strong>*</strong> Prices are base price (not counted qty.)
      </td>
    </tr>

    <!--items-->
    <ItemListWithAddons
      :items="order.items"
      :show-price="showPrice"
      :item-font-size="state.itemFontSize"
      :addon-font-size="state.addonFontSize"
    />

    <!--set menus-->
    <tr v-if="order.set_menus?.length">
      <td colspan="2" class="section-title">Set Menus</td>
    </tr>

    <template
      v-for="setMenu in order.set_menus"
      :key="setMenu.id"
    >
      <tr
        :style="{
          fontSize: state.itemFontSize
        }"
      >
        <td class="text-info">{{ setMenu.qty }}</td>
        <td class="text-info fw-bold">{{ setMenu.name }}</td>
        <td v-if="showPrice" class="text-info">{{ setMenu.price }}</td>
      </tr>

      <!--set menu items & addons-->
      <ItemListWithAddons
        :items="setMenu.items"
        hide-qty
        :show-price="showPrice"
        :item-font-size="state.itemFontSize"
        :addon-font-size="state.addonFontSize"
      />
    </template>

    </tbody>
  </table>
</template>

<script>
import { dateFormat } from '@/Mixins/appHelper';
import ItemListWithAddons from '@/components/OrderMenu/ItemListWithAddons';
import { reactive } from 'vue';

export default {
  name: 'OrderMenuDetails',
  components: { ItemListWithAddons },
  props: {
    order: {
      type: Object,
      required: true
    },
    showPrice: {
      type: Boolean,
      default: false
    },
    showPriceInfo: {
      type: Boolean,
      default: false
    },
    useBigFont: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const state = reactive({
      itemFontSize: props.useBigFont ? '35px' : '18px',
      addonFontSize: props.useBigFont ? '25px' : '14px'
    });

    return {
      state,
      dateFormat,
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/sass/variables";
.table-striped tbody tr:nth-of-type(odd) {
  background: $card-bg;
}
.table {
  background: $data-table-bg !important;
  color: $title-color;
  tbody {
    tr {
      color: $title-color;
      td {
        color: $white !important;
      }
    }
  }
}
.table td {
  border-top: none;
  border-bottom: 1px solid $data-table-border;
}
.time-bg {
  border-radius: 100px;
  background-color: transparent;
}
.bg-transparent {
  background-color: transparent;
}
.section-title {
  background-color: #eceaea;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
