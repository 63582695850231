<template>
  <ModalComp
    :show="modelValue"
    title-icon-class="icon-import_contacts"
    :title-text="`Order Menus for Processing (${orders.length})`"
    custom-class="w-100 h-100 order-menu-list-popup"
    max-width="100%"
    max-height="100%"
    :show-action-button="false"
    @hide="closeModal"
  >

    <div class="row bg-default py-2">

      <!--selected menu-->
      <div
        v-if="selectedOrder.id"
        class="col-sm-12 col-md-6 pinned-section"
      >
        <OrderMenuCard
          :selected-order="selectedOrder"
          :order="selectedOrder"
          fullscreen-view
        />
      </div>

      <div
        v-if="!state.orders.length"
        class="col-12 text-center mt-5">
        <div class="h3 text-light">No order available for processing</div>
      </div>

      <!--list-->
      <StackedAnimation
        :class="`col-sm-12 col-md-${selectedOrder.id ? 6 : 12} menu-section`"
        duration="2"
        stack-duration="1"
      >
        <div
          v-for="order in state.orders"
          :key="order.id"
          class=""
        >
          <OrderMenuCard
            :order="order"
            :selected-order="selectedOrder"
            @orderSelect="fireOrderSelectEvent(order)"
          />
        </div>
      </StackedAnimation>
    </div>

  </ModalComp>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import OrderMenuCard from '@/components/OrderMenu/OrderMenuCard';
import StackedAnimation from '@/components/Util/Animations/StackedAnimation';

export default {
  name: 'OrderMenuListPopUp',
  components: { StackedAnimation, OrderMenuCard, ModalComp },
  emits: ['update:modelValue', 'orderSelect'],
  props: {
    modelValue: { // use with v-model for show hide
      type: Boolean
    },
    orders: {
      type: Array,
      required: true
    },
    selectedOrder: {
      type: Object,
      required: true
    }
  },

  setup (props, { emit }) {

    const state = reactive({
      orders: computed(() => {
        return props.orders.filter(order => order.id !== props.selectedOrder.id)
      }),
    });

    const closeModal = (value) => {

      // update only when modal closes
      if (!value) {
        emit('update:modelValue', value);
      }
    };

    const fireOrderSelectEvent = (order) => {
      emit('orderSelect', order);
    };

    watch(() => props.modelValue, (nv) => {
      closeModal(nv);
    });

    return {
      state,
      closeModal,
      fireOrderSelectEvent
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";

.bg-default {
  background-color: $data-table-bg !important;
}
.pinned-section {
  padding-right: 0;
  padding-left: 20px;
}
.menu-section {
  height: 100vh;
  overflow: auto;
}
</style>

<style lang="scss">
@import "src/assets/sass/variables";

.order-menu-list-popup {
  .p-dialog-content {
    padding: 0 12px 0 0 !important;
    height: 100% !important;
    overflow: hidden;
  }
}
.pinned-section {
  .card-header {
    background-color: transparent !important;
  }
  .card-body {
    height: 100vh !important;
    overflow: auto !important;
  }
}
</style>
